<template>
  <div class="m-3">
    <h1 class="page-title" :style="titleColor" >Dashboard</h1>
  </div>
</template>

<script>
// import DashBox from '@/components/dash/DashBox'
// import {DateTime} from 'luxon'
import FranchiseMixin from '@/mixins/franchise-style';

export default {
  name: 'BoardUser',

  data(){
    return {
      // today: DateTime.local().toISO()
    }
  },
  // methods: {
    
  // },
  mixins:[FranchiseMixin],
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  // components: {DashBox}
};
</script>

<style>
  
</style>